// 网点商品
import * as API from '@/api/index'
export default {
    /**  商品列表*/
    GoodAll: params => {
        return API.POST('api/Commodity/all', params)
    },
    /**  商品分类*/
    GoodType: () => {
        return API.POST('api/Commodity/GoodType')
    },
    /**  类型下的商品*/
    offlineGoods: params => {
        return API.POST('api/Commodity/offlineGoods', params)
    },
    /**  线上分类 - 排除没有子类的分类*/
    AppletGoodsCategoryValid: params => {
        return API.POST('api/appletGoodsCategory/valid', params)
    },
    /**  上传图片*/
    AddImage: params => {
        return API.POST('api/Commodity/addImage', params)
    },
    /**  添加商品*/
    Create: params => {
        return API.POST('api/Commodity/create', params)
    },
    /**  编辑商品*/
    Update: params => {
        return API.POST('api/Commodity/update', params)
    },
    /**  批量修改商品状态*/
    UpdateGoodsActive: params => {
        return API.POST('api/Commodity/updateGoodsActive', params)
    },
    /**  批量修改商品分类*/
    UpdateGoodsCategory: params => {
        return API.POST('api/Commodity/updateGoodsCategory', params)
    },

}